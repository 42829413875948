@if require-font('Merriweather') {

/* merriweather-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/merriweather-v30-latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* merriweather-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/merriweather-v30-latin_latin-ext-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* merriweather-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/merriweather-v30-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* merriweather-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/merriweather-v30-latin_latin-ext-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* merriweather-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/merriweather-v30-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* merriweather-700italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/merriweather-v30-latin_latin-ext-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

} // end @if
