@if require-font('Fira Sans') {

/* fira-sans-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-500italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-600 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-600italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-sans-700italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/fira-sans-v17-latin_latin-ext-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

} // end @if